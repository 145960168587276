import dayjs from 'dayjs'
import { Journey, Leg, Discount } from '@/models/Journey'
import { PromoCodeValidation } from 'src/models/Journey'

const stripTags = (text: string) => text.replace(/(<([^>]+)>)/gi, '')

const addEllipses = (text: string) => text.replace(/[^A-Za-z0-9)]*$/, '...')

const truncate = (text: string, maxLength: number) => {
  text = stripTags(text)
  if (text.length <= maxLength) return text

  text = text.substring(0, maxLength + 1)
  text = text.substring(0, text.lastIndexOf(' ')) || text
  return addEllipses(text)
}

const journeySubheading = (journey: Journey, isPartOfRoundtrip = false) => {
  const originName = journey.OriginCityName || journey.OriginStopName
  const destinationName = journey.DestinationCityName || journey.DestinationStopName

  return `${originName} to ${destinationName}` +
  `${isPartOfRoundtrip ? ' (Return)' : ''}` +
  `, ${dayjs(journey.DepartureDateTime).format('MMMM D')}`
}

const formatTimeDiff = (hours: number, minutes: number) => {
  if (hours > 0 && minutes > 0) {
    return `${hours}hr ${minutes} min`
  } else {
    return hours > 0 ? `${hours}hr` : `${minutes} min`
  }
}

const calculateTimeDiff = (fromDate: string, toDate: string) => {
  const to = dayjs(toDate)
  const from = dayjs(fromDate)
  const hours = to.diff(from, 'hour')
  const minutes = to.diff(from, 'minute') % 60
  return formatTimeDiff(hours, minutes)
}

const resolveDiscountMessage = (discounts: Discount[]):string => {
  const codesApplied = new Map()
  discounts?.forEach(discount => {
    if (discount.PromoCodeValidationResult?.Code === 'ok') {
      codesApplied.set(
        discount.PromoCodeValidationResult.Description,
        discount.PromoCodeValidationResult.Description
      )
    }
    discount.SpecialDiscounts?.forEach(specialDiscount => {
      codesApplied.set(
        specialDiscount,
        specialDiscount
      )
    })
  })

  const values = [] as PromoCodeValidation[]
  codesApplied.forEach(element => {
    values.push(element)
  })
  if (values.length === 0) return ''
  const res = values.length === 1
    ? `${values[0]} Discount Applied`
    : 'Multiple Discounts Applied'
  return res
}

const calculateJourneyDiscountStatus = (legs: Leg[]):string => {
  const codesApplied = new Map()
  legs.forEach(leg => {
    if (!leg.Pricing) return

    leg.Pricing.forEach(price => price.Discounts?.forEach(discount => {
      if (discount.PromoCodeValidationResult?.Code === 'ok') {
        codesApplied.set(
          discount.PromoCodeValidationResult.Description,
          discount.PromoCodeValidationResult.Description
        )
      }
      discount.SpecialDiscounts.forEach(specialDiscount => {
        codesApplied.set(
          specialDiscount,
          specialDiscount
        )
      })
    }))
  })
  const values = [] as PromoCodeValidation[]
  codesApplied.forEach(element => {
    values.push(element)
  })
  if (values.length === 0) return ''

  return values.length === 1
    ? `${values[0]} Discount Applied`
    : 'Multiple Discounts Applied'
}

const formatPrice = (price: number) => `€${price.toFixed(2)}`
const formatDate = (date?: string) => date && dayjs(date).format('ddd, D MMM YY')
const altFormatDate = (date?: string) => date && dayjs(date).format('D/MM/YY')
const formatTime = (date?: string) => date && dayjs(date).format('HH:mm')
export {
  resolveDiscountMessage,
  calculateJourneyDiscountStatus,
  stripTags,
  addEllipses,
  truncate,
  journeySubheading,
  formatDate,
  formatTime,
  formatPrice,
  calculateTimeDiff,
  altFormatDate,
  formatTimeDiff
}
